import React from "react";
import { Carousel, Button } from 'react-bootstrap';
import Firstimage from '../images/frontpage/RegisterCrop.jpg'
import Seccondimage from '../images/frontpage/StockCrop.jpg'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

const Imageslider = () => {
    return(
        <Carousel className="container-center my-auto">
            <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={Firstimage}
                  alt="Reception room with products and a register"
                />

                <Carousel.Caption>
                    <button className="my-auto p-2 Carousel-Button">
                        <Link to="/omoss" className="text-white">
                            Om Oss
                        </Link>
                    </button>                    
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={Seccondimage}
                  alt="A stock room with a lot of animal food packaged"
                />

                <Carousel.Caption>
                    <button className="my-auto p-2 Carousel-Button">
                        <Link to="/produkter" className="text-white">
                            Produkter
                        </Link>
                    </button>                    
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
        

    )
    
}

export default Imageslider