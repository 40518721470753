import './App.css';
import './css/Root.css';
import './css/Navbar.css';
import './css/Frontpage-Content.css';
import './css/contact-and-captcha.css';
import './css/ImageSlider.css';
import { useEffect, useState } from "react";
import productApi from './api/ProductApi';
import Content from './components/Content';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Contact from './components/Contact';
import Products from './components/Products';
import AboutUs from './components/AboutUs';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import * as smoothscroll from 'smoothscroll-polyfill';
import ProductInfo from './components/ProductInfo';
import ContactInfo from './components/ContactInfo';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

smoothscroll.polyfill();

function App(){

  const [products, setProducts] = useState([]);

  const getProducts = async () => {
    const product = await productApi.getAllProducts();
    console.log(product);
    setProducts(product);
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Router>      
      <div>
        <Navbar/>
        <Switch>
          <Route path="/produkter">
            <ProductInfo/>
            <Products allProducts = {products}/>            
          </Route>
          <Route path="/omoss">
            <AboutUs/>
          </Route>
          <Route path="/kontakt">
            <ContactInfo/>
            <Contact />
          </Route>
          <Route path="/">
            <Content/>
          </Route>
        </Switch>
      </div>
      <Footer/>
    </Router>
  );
}
export default App;

