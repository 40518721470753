import React from 'react';

const ContactInfo = () =>{
    return(
    <div className="d-flex justify-content-center">
        <div className="Container ProductFontSize mb-5 mx-2">
            <b className="d-flex justify-content-center contactFontSize">Hjärtligt välkomna till vår butik!</b> <br/><br/>
            <div className="d-flex nav justify-content-center border-bottom border-top pb-3 mb-4">
                <div className="mx-8 mb-3">
                <br></br> Besöksadress:<br></br>Allégatan 90<br></br>694 30&nbsp;&nbsp;Hallsberg<br></br>Sverige
                </div>
                <div>
                <br></br>  Öppettider:<br></br>Måndagar 16.00 - 19.00<br></br>Onsdagar 16.00 - 18.00<br></br>Fredagar&nbsp;&nbsp;16.00 - 18.00
                </div>
            </div>
            
            <b className="d-flex justify-content-center">Lämna gärna ett meddelande nedan.</b>
        </div>
    
    </div>
    )
}

export default ContactInfo;