import { Button, Image } from 'react-bootstrap';
import React from 'react'

import Firstimagemobile from '../images/frontpage/AboutFrontImage.jpg'
import Secondimagemobile from '../images/frontpage/StockFrontpageMobile.png'
import Thirdimagemobile from '../images/frontpage/Doggo1.jpg'
import firstabout from '../images/frontpage/PhotoInfrontBuissiness.png'
import secondabout from '../images/frontpage/Foder2.jpg'

import ContactForm from './ContactForm'
import Imageslider from './ImageSlider'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";


const Content = (props) =>{
    
    const scrollToAbout = () => {
        window.scrollTo({
            top: 1000,       
            behavior: 'smooth'
        });
    };


    return(
        <div>
            
            {/* Desktop */}
            <div className="d-none d-sm-block mb-5">
                <div className="d-flex container my-auto">
                    <Imageslider/>                    
                </div>
            </div>


            {/* Mobile */}
            <div className="d-sm-none">
                <div className="container-fluid">
                    <figure className="position-relative">
                        <Image src={Firstimagemobile} className="frontimg img-fluid" alt="Cash Register"></Image>
                        <button onClick={scrollToAbout} className="mx-auto p-2 w-10 pill-rounding f-buttonFirst">
                            Om Oss
                        </button>
                    </figure>
                </div>
            </div>
            
            <div className="d-sm-none">
                <div className="container-fluid">
                    <figure className="position-relative">
                        <Image src={Secondimagemobile} className="frontimg img-fluid" alt="Cash Register"></Image>

                        <button className="mx-auto p-2 f-buttonSecond text-white">
                            <Link to="/produkter" className="text-white">Produkter</Link>
                        </button>
                        
                    </figure>
                </div>
            </div>
            <div className="d-sm-none">
                <div className="container-fluid">
                    <figure className="position-relative">
                        <Image src={Thirdimagemobile} className="frontimg img-fluid" alt="Cash Register"></Image>
                        
                        <button className="mx-auto p-2 w-10 pill-rounding f-buttonThird">
                            <Link to="/kontakt" className="text-white">
                                Kontakta oss
                            </Link>
                        </button>

                        
                    </figure>
                </div>
            </div>
            
            <div className="d-md-none">
                <div className="Aboutmobile-Title">Om oss</div>
                <img src={firstabout} className="AboutMobileImage" />
                <p className="Aboutmobile-Text mt-4">
                    Min Vän & Jag är ett familjeföretag som drivs av mor och dotter Camilla o Cina Wellsén.
                    Idén växte fram i slutet av 2020 när Cina ville sälja hästfoder via hemleverans.
                    <div className="mt-2">Den lokala foderbutiken i Hallsberg hade lagt ned under hösten.
                    Vilket var en stor förlust för oss som handlade där.
                    Min Vän & Jag började ta form, utifrån det vi själva önskade skulle finnas på orten.</div>
                </p>
                <img src={secondabout} className="AboutMobileImage" />
                <p className="Aboutmobile-Text mt-4">

                    Resultatet blev den lilla butiken med det varma hjärtat. 
                    Personlig service i en mysig miljö, där både du och din hund är välkomna in i butiken. 
                    I vårt sortiment finns ett utbud till både dig och ditt djur.
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <div className="mt-4">Djurfoder och djurtillbehör, &nbsp;&nbsp; tillskott till hund, &nbsp;&nbsp; 
                    häst, &nbsp;&nbsp; katt, &nbsp;&nbsp; höns, &nbsp;&nbsp; Inredning,
                    Kläder, &nbsp;&nbsp; Hjälpmedel, &nbsp;&nbsp; 
                    Hö till smådjur-beställningsvara (förvaras ej i butiken) och &nbsp;
                    Kloklippning.</div>
                </p>
            </div>
            <div className="d-md-none">
                <div className="Contact-Title">Kontakta oss</div>
                <ContactForm></ContactForm>
            </div>
        
        </div>
        
    )
}
export default Content;