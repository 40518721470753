import React from 'react'
import '../css/footer.css'
import f_logo from '../assets/f_logo.png';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

const Footer = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,       
            behavior: 'smooth'
        });
    };

    return(

    <footer className="footer-center">
        {/* Desktop */}
        <footer className="d-none d-lg-block fluid-container footer footer-mt-2 py-3 bg-light ">
            <ul className="d-flex nav justify-content-center border-bottom pb-3 mb-4">
                <Link to="/"            className="nav-link px-2 text-muted">Hem</Link>
                <Link to="/produkter"   className="nav-link px-2 text-muted">Produkter</Link>
                <Link to="/omoss"       className="nav-link px-2 text-muted">Om&nbsp;Oss</Link>
                <Link to="/kontakt"     className="nav-link px-2 text-muted">Kontakta&nbsp;Oss</Link>          
                <li className="nav-item">
                    <a href="https://www.facebook.com/dancetogether.se/" target="_blank" rel="noopener noreferrer" className="nav-link px-2 f_logo"><img src={f_logo}></img></a>
                </li>
            </ul>
            <div className="d-flex nav justify-content-center border-bottom pb-3 mb-4 text-muted">
                 <div className="">
                    Huvudbolag Rise and Shine i Örebro<br></br>
                    Företaget innehar F-skattsedel<br></br> 
                    orgnr: 556879-6311
                </div>
                <div className="mx-8">
                    Besöksadress:<br></br>Allégatan 90<br></br>694 30&nbsp;&nbsp;Hallsberg<br></br>Sverige
                </div>
                <div>
                    Öppettider:<br></br>Måndagar 16.00 - 19.00<br></br>Onsdagar 16.00 - 18.00<br></br>Fredagar&nbsp;&nbsp;16.00 - 18.00
                </div>
                <div className="mx-8">
                    Telefon:<br></br>Cina 073-716 32 89<br></br>Camilla 070-331 34 99
                </div>
            </div>
            <p onClick={scrollToTop} className="text-center text-muted">Min Vän Och Jag</p>
        </footer>

        {/* Mobile */}
        <footer className="d-lg-none fluid-container footer footer-mt-2 py-3 bg-light">
            <ul className="d-flex nav justify-content-center border-bottom pb-3 mb-3">
                <Link to="/"            className="nav-link px-2 text-muted">Hem</Link>
                <Link to="/produkter"   className="nav-link px-2 text-muted">Produkter</Link>         
                <li className="nav-item">
                    <a href="https://www.facebook.com/dancetogether.se/" target="_blank" rel="noopener noreferrer" className="nav-link px-2 f_logo"><img src={f_logo}></img></a>
                </li>
            </ul>
            <div className="d-flex nav justify-content-center border-bottom pb-3 mb-4 text-muted">                
                <div className="mb-3">
                    Besöksadress:<br></br>Allégatan 90<br></br>694 30&nbsp;&nbsp;Hallsberg<br></br>Sverige
                </div>
                <div className="mx-8 mb-3">
                    Öppettider:<br></br>Mån 18.00 - 20.00<br></br>Ons 18.00 - 20.00<br></br>Fre&nbsp;&nbsp;18.00 - 20.00
                </div>
                <div>
                    Telefon:<br></br>Cina 073-716 32 89<br></br>Camilla 070-331 34 99
                </div>
            </div>
            <p onClick={scrollToTop} className="text-center text-muted">Min Vän Och Jag</p>
        </footer>
    </footer>
    )
}
export default Footer;