import React from 'react'
import '../css/Aboutus.css'
import firstabout from '../images/frontpage/PhotoInfrontBuissiness.png'
import secondabout from '../images/frontpage/Foder2.jpg'

const AboutUs = () => {
    return(
        <div>
            <div className="container aboutUs mb-5">
                <div>
                    <div className="my-auto">
                        <div className="firstlinetext">Butiken för dig och ditt djur.</div>
                        <div className="container position-relative">
                            
                            <img src={firstabout} className="aboutimage1" alt="2 induviduals and 2 dogs infront of house"></img>                            
                            <div className="imagelinetext">
                                Min Vän & Jag är ett familjeföretag som drivs av mor och dotter Camilla o Cina Wellsén.
                                Idén växte fram i slutet av 2020 när Cina ville sälja hästfoder via hemleverans. Den lokala foderbutiken i Hallsberg hade lagt ned under hösten. Vilket var en stor förlust för oss som handlade där.
                                Min Vän & Jag började ta form, utifrån det vi själva önskade skulle finnas på orten.
                            </div>                            
                        </div>

                        <div className="container position-relative">

                            <div className="imagelinetext2">
                            Resultatet blev den lilla butiken med det varma hjärtat. 
                            Personlig service i en mysig miljö, där både du och din hund är välkomna in i butiken. 
                            I vårt sortiment finns ett utbud till både dig och ditt djur. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Djurfoder och djurtillbehör, &nbsp;&nbsp; tillskott till hund, &nbsp;&nbsp; 
                            häst, &nbsp;&nbsp; katt, &nbsp;&nbsp; höns, &nbsp;&nbsp; Inredning,
                            Kläder, &nbsp;&nbsp; Hjälpmedel, &nbsp;&nbsp; 
                            Hö till smådjur-beställningsvara (förvaras ej i butiken) och &nbsp;
                            Kloklippning.
                            </div> 
                            
                            <img src={secondabout} className="aboutimage1" alt="2 induviduals and 2 dogs infront of house"></img>                            
                                                       
                        </div>

                        <div>
                            
                        </div>

                    </div>

                    <p className="mt-5">
                    </p>
                    
                </div>
            </div>
        </div>
    )
}
export default AboutUs;