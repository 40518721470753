import React from 'react';

const ProductInfo = () =>{
    return(
    <div className="d-flex justify-content-center">
        <div className="Container ProductFontSize mb-5 mx-2">
            <b>I vårt sortiment finns ett utbud till både dig och ditt djur.</b> <br/><br/>
            <ul>
                <li>Djurfoder och djurtillbehör</li>
                
                <li>Tillskott till hund, häst, katt och höns</li>
                
                <li>Inredning</li>
                
                <li>Kläder</li>
                
                <li>Hjälpmedel</li>
                
                <li>Hö till smådjur-beställningsvara (förvaras ej i butiken)</li>
                
                <li>Kloklippning</li>
            </ul>
            
            <b>Fråga oss gärna om det är något du saknar.</b>
        </div>
    
    </div>
    )
}

export default ProductInfo;