import React from 'react'
import ProductItem from '../components/ProductItem'
import '../css/card.css'


const Products = (props) => {
    const {allProducts} = props;
    return(
        
        <div className="d-flex justify-content-center">
            
            <div className="d-none d-sm-block card-frame">
                
                    <div className="d-flex justify-content-center position-relative fluid-container row">                    
                        {allProducts.map(prods =><ProductItem key={prods.Id} prods={prods} />)}                       
                    </div>
          
            </div>

            <div className="d-block d-sm-none card-frame-xs d-flex justify-content-center align-items-center">
                
                <div className="d-flex justify-content-center position-relative fluid-container row">                    
                    {allProducts.map(prods =><ProductItem key={prods.Id} prods={prods} />)}                       
                </div>
            </div>
        </div>

    )
}
export default Products;

