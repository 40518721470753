import React from 'react';
import {Button} from 'react-bootstrap'
import '../css/contact-and-captcha.css';
import ReCAPTCHA from "react-google-recaptcha"
import '../css/contact-and-captcha.css';


export default class Formu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      "name": "",
      "email": "",
      "message": "",
      "g-recaptcha-response": ""
    }
  }  

DisableButton() {
  var element = document.getElementById("button");

  if (element.disabled === false) {
    element.disabled = true;
  } else {
    element.disabled = false;
  }
}

ChangeElement(id) {
  var styleAfterMessage = document.getElementById(id);
  styleAfterMessage.style.color = "#76a345";
  styleAfterMessage.style.fontSize = "1.5rem";
  styleAfterMessage.style.textAlign = "center";
}

  render() {
    return(
      <div className="d-flex justify-content-center mb-5">
        <div className="contactSize bg-light p-2 border border-3 border-round contactBorderColor">
          <form id="contact-form" onSubmit={this.handleSubmit.bind(this)}>
            <div className="form-group">
              <input required name="name" type="text" placeholder="Namn" className="form-control mb-1" value={this.state.name} onChange={this.onNameChange.bind(this)}/>
            </div>
            <div className="form-group">
              <input required name="email" type="email" placeholder="Email Adress" className="form-control mb-1" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
            </div>
            <div className="form-group">
              <textarea required name="message" placeholder="Meddelande" className="form-control" rows="5" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
            </div>
            <div className="position-relative">
              <Button id="button" type="submit" className="submit-button pill-rounding shadow">Submit</Button>
            </div>
          </form>
          <ReCAPTCHA sitekey="6Leq6RIcAAAAAN5Tdm5ekykhPKMhguIP_kgDWeHg" className="d-flex captchascale" />

        </div>
        
      </div>
                  // <ReCAPTCHA sitekey="6Leq6RIcAAAAAN5Tdm5ekykhPKMhguIP_kgDWeHg" className="captchaCenter position-relative content-fluid captchascale" />

    );
  }

  onNameChange(event) {
    this.setState({"name": event.target.value})
  }

  onEmailChange(event) {
    this.setState({"email": event.target.value})
  }

  onMessageChange(event) {    
    this.setState({"message": event.target.value})    
  }

  handleSubmit(event) {
    event.preventDefault();

    this.state['g-recaptcha-response'] = document.getElementById("g-recaptcha-response").value

    // alert(this.state['g-recaptcha-response'])
    this.DisableButton();
    
    const data = this.state;
    const jsonData = JSON.stringify(data);

        
    // fetch("https://minvanochjag.se/mailform.php", { method: "POST", body: jsonData})
    // .then(response => response.json())
    // .then(response => {if(!response.success) this.DisableButton();
    // else document.getElementById("contact-form").innerHTML=data.msg
    // .then(this.ChangeElement("contact-form"));

    // fetch("https://minvanochjag.se/mailform.php", { method: "POST", body: jsonData})
    // .then(response => response.json()).then(response => {
    // if(!response.success) {
    // this.DisableButton();
    // } 
    // else  { 
    //     document.getElementById("contact-form").innerHTML=data.msg
    // .then(this.ChangeElement("contact-form"));
    
    fetch("https://minvanochjag.se/mailform.php", { method: "POST", body: jsonData})
    .then(response => response.json())
    .then(response =>
      {
        if(!response.success)
        {
          this.DisableButton();
        }
        else
        { 
          document.getElementById("contact-form").innerHTML=response.msg;
          this.ChangeElement("contact-form");
        }
      }
    );
  }
}

  

