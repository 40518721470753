import React from 'react'
import Captcha from './Captcha';
import ContactForm from './ContactForm';

const Contact = () => {
    return(
    <div className="contact">
        <div><ContactForm/></div>
        {/* <div><Captcha/></div> */}
    </div>
    )
}
export default Contact;