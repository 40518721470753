import React from 'react'
import logo from '../images/logos/Logo with transparent background.svg'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";


// const home = () => {
//     location.href="https://www.minvanochjag.se"
// }


const Navbar = () => {

    return(
    <nav className="container-fluid">        
        <div className="d-none d-md-block">
            <div className="d-flex justify-content-center">
                <div className="d-flex flex-fill rowMargin">
                <img src={logo} className="d-flex logo" alt="LogoTyp Min vän och jag"></img>        
                    <Link to="/"            className="d-flex Nav col align-items-center">Hem</Link>
                    <Link to="/produkter"   className="d-flex Nav col align-items-center">Produkter</Link>
                    <Link to="/omoss"       className="d-flex Nav col align-items-center">Om&nbsp;oss</Link>
                    <Link to="/kontakt"     className="d-flex Nav col align-items-center">Kontakta&nbsp;oss</Link>
                </div>
            </div>
        </div>

        {/* <a href="https://www.minvanochjag.se"><img src={logo} className="d-flex logo" alt="LogoTyp Min vän och jag"></img>  </a>          */}

        <div className="d-block d-sm-block d-md-none mobile">
            <div className="d-flex align-items-center">
                <img src={logo} className="logo mx-auto" alt="LogoTyp Min vän och jag"></img>
            </div> 
        </div>          
    </nav>
    )
}
export default Navbar;
