import React from "react";
import '../css/card.css'



const ProductItem = (props) => {
  const {prods} = props;

  return (
   
    <div className="d-flex justify-content-center col-md-6 col-lg-6 col-xxl-6 mb-4">
        <div className="card bg-card text-card p-4 mb-4 card-width">
          <div className="image-square py-auto"> 
            <img className="card-img-top" src={prods.ImageURL} alt="Card image cap"/>
          </div>          
          <div className="card-body">
            <h3 className="card-title d-flex justify-content-center">{prods.ProductName}</h3>
            <p className="card-text d-flex justify-content-center">{prods.ProductInfo}</p>
          </div>
        </div>
    </div>   
  
  );
};
export default ProductItem;
