const apiAddress = "https://minvanochjag.se/api/product/";

const productApi = {
    getAllProducts: async () => {
        const response = await fetch(apiAddress);
        if (response.ok) {
          const result = await response.json();
          return result;
        }
        throw new Error({
          status: response.status,
          statusText: response.statusText,
        });
      },
    };

export default productApi;
